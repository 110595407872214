import React, { useState } from 'react'

const CategoryButton = ({ galleries, setGalleries, setBackToAll }) => {
    
    const [index, setIndex ] = useState(0)
    
    const types = ['all', ...new Set(galleries.map(gallery => {
        return gallery.category
    }))] 

    const showGalleries = (type, typeIndex) => {
        setIndex(typeIndex)
        if (type === 'all') {
            setBackToAll()
        } else {
            const tempGalleries = galleries.filter(gallery => gallery.category === type)
            setGalleries(tempGalleries)
        }
    }

    return (
        <div className='section-center cat-button-div'>
            <div>
            {types.map((type, typeIndex) => {
                return (
                    <button
                        key={typeIndex}
                        className={index === typeIndex ? 'active cat-button' : 'cat-button'}
                        onClick={() => showGalleries(type, typeIndex)}
                    >
                    {type}
                    </button>
                )
            })}
            </div>
            
        </div>
    )
}

export default CategoryButton
