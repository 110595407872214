import React, {useState} from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import CategoryButton from '../components/CategoryButton'


const Galleries = ({ galleries: data }) => {

    const [galleries, setGalleries] = useState(data)

    const setBackToAll = () => {
        setGalleries(data)
    }
    
    return (
        <>
        <CategoryButton
            galleries={data}
            setGalleries={setGalleries}
            setBackToAll={setBackToAll}
        />
        <div className='section-center grid-gallery'>
            {galleries.map(( item ) => {
                const { id, alt, image } = item
                
                return (
                    <div key={id} className='gallery-grid-items'>
                        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} className='img-grid' />
                    </div>
                )
            })}
        </div>
        </>
    )
}

export default Galleries
