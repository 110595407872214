import React from 'react'
import Title from "../components/Title"
import Seo from "../components/Seo"
import Galleries from '../components/Galleries'
import { graphql } from "gatsby"



const gallery = ({ data }) => {
    

    const { allGalleryDataJson: { nodes: galleries } } = data
 
    return (
        <>
            <Seo title="Gallery" />
            <section className='gallery-page'>
                <Title title='Our Gallery' />
                <Galleries galleries={galleries} />
            </section>
        </>
    )
}


export const query = graphql`
  {
    allGalleryDataJson {
      nodes {
        id
        category
        alt
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
  }
`


export default gallery
